import React from 'react'

export default function FourOrFour() {
  return (
    <>
      <div style={{ height: '100vh', display: 'flex', alignItems: 'flex-end', paddingBottom: 180 }}>
        <img src="/assets/images/lost-386x999.png" style={{ maxHeight: '100%' }} alt="404 Lost in the Dark" />
      </div>
    </>
  )
}