export const SYNC_PLAYER_STATE = `sync_player_state`
export const PLAY_LIST = `play_list`
export const RESUME_LIST = `resume_list`
export const PAUSE_PLAYER = `pause_player`
export const PAUSE = "pause"
export const PLAY = "play"
export const RESUME = "resume"
export const PAUSE_SOUND = "pauseSound"
export const RESUME_SOUND = "resumeSound"
export const PLAY_SOUND = "playSound"
export const PLAY_NEXT = "play_next"
export const ADD_TO_QUEUE = "add_to_queue"