import React from "react"
// import { useSelector } from "react-redux";

// import ListTable from '../components/ListTable';
// import AppStateInterface from "../interfaces/AppStateInterface";

function YourPlaylistsScreen() {
  // const list = useSelector(({ player }: AppStateInterface) => player.list);

  return (
    <>
      <h1>Playlists You Liked</h1>

      {/* {!list && <h3>Your queue is empty!</h3>} */}

    </>
  )
}

export default YourPlaylistsScreen