import React from 'react'
import ReactDOM from 'react-dom'
// import * as serviceWorker from './serviceWorker'

// import './config'
import App from './App'

ReactDOM.render(<App />, document.getElementById('app'))

// serviceWorker.unregister()
